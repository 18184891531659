<template>
  <div style="box-sizing: border-box;padding-left: 16px;height: 1px;width: 100%;">
    <div style="height: 1px;background: #e5e5e5;"></div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>