<template>
  <div class="top-header">
    <div style="width: 20px;height: 20px;margin-right: 8px;" @click="handleGoApp">
      <img style="height: 100%;width: 100%;" src="@/assets/images/backImg.png" alt />
    </div>
    <div style="color: #000;font-weight: 500;">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "topHeader",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    returnPath: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  methods: {
    handleGoApp() {
      if (this.returnPath) {
        this.$router.push({
          name: this.returnPath
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-header {
  width: 100%;
  background: #fff;
  height: 44px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  max-height: 44px !important;
  min-height: 44px !important;
}
</style>
